import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, PreviewToolEnum, SettingType, SortTypeEnum } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogCreateDailyScheduleComponent } from 'app/dialog/dialog-create-daily-schdule/dialog-create-daily-schedule.component';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogDuplicateSettingComponent } from 'app/dialog/dialog-duplicate-setting/dialog-duplicate-setting.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogSettingSignageDisplayComponent } from 'app/dialog/dialog-setting-signage-display/dialog-setting-signage-display.component';
import { DialogTextHighlightingSettingComponent } from 'app/dialog/dialog-text-highlighting-setting/dialog-text-highlighting-setting.component';
import { DialogTimetableUpdateTimingComponent } from 'app/dialog/dialog-timetable-update-timing/dialog-timetable-update-timming.component';
import { DialogTemplateSettingScheduleComponent } from 'app/dialog/schedule/dialog-template-setting-schedule/dialog-template-setting-schedule.component';
import { Area } from 'app/model/entity/area';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { ContentDay } from 'app/model/entity/content-day';
import { IndexWord } from 'app/model/entity/index-word';
import { Media } from 'app/model/entity/media';
import { ContentDaySchedule } from 'app/model/entity/schedule/content-day-schedule';
import { Timetable } from 'app/model/entity/schedule/timetable';
import { TimetableScheduleMerge } from 'app/model/entity/schedule/timetable-schedule';
import { TimetableDaily } from 'app/model/entity/schedule/timetables-daily';
import { UserCalendar } from 'app/model/entity/schedule/user-calendar';
import { SettingSignageChannel } from 'app/model/entity/simple/setting-signage-channel';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { CustomTagService } from 'app/service/custom-tag.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { DrawScheduleMergeService } from 'app/service/draw-schedule-merge.service';
import { ExecutingService } from 'app/service/executing.service';
import { IndexWordService } from 'app/service/index-word.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { PublishTimetableService } from 'app/service/publish-timetable.service';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { ScheduleRegistrationService } from 'app/service/schedule-registration.service';
import { SettingSignageChannelService } from 'app/service/setting-signage-channel.service';
import { SimpleMediaService } from 'app/service/simple/simple-media.service';
import { SortFilterService } from 'app/service/sort-filter.service';
import { TemplateService } from 'app/service/template.service';
import { TimetableService } from 'app/service/timetable.service';
import { UserService } from 'app/service/user.service';
import { TableSortFilterComponent } from 'app/shared/table-sort-filter/table-sort-filter.component';
import { AppState } from 'app/store/app.state';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { RegexTime } from '../timetable-editor/timetable-editor.component';
@Component({
  selector: 'schedule-merge',
  templateUrl: './schedule-merge.component.html',
  styleUrls: ['./schedule-merge.component.scss']
})
export class ScheduleMergeComponent implements OnInit {
  @ViewChild(TableSortFilterComponent, { static: false })
  tableSortFilterComponent: TableSortFilterComponent;

  /**
   * PATH_ANGLE_DOUBLE_RIGHT
   */
  public PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  /**
   * PreviewToolEnum
   */
  public PreviewToolEnum = PreviewToolEnum;
  /**
   * Tab_Enum
   */
  public Tab_Enum = Tab_Enum;
  /**
   * true if expand preview
   */
  public isEnlargePreview: boolean = true;
  /**
   * true if active tool pan
   */
  public isPan: boolean;
  /**
   * true if active tool pan
   */
  public isZoom: boolean;
  /**
   * tab active selected
   */
  public tabSelected: Tab_Enum = Tab_Enum.CONFIRMATION;
  /**
   * selected day
   */
  public selectedDay: ContentDaySchedule;
  /**
   * view child element divContainCanvas
   */
  @ViewChild('divContainCanvas', { static: false })
  public divContainCanvas: ElementRef;
  /**
   * view child element divPreviewDisplay
   */
  @ViewChild('divPreviewDisplay', { static: false })
  public divPreviewDisplay: ElementRef;
  /**
   * panzoom display
   */
  private panzoomDisplay: PanzoomObject;
  /**
   * timetable selected
   */
  public timetableSelected: Timetable;
  /**
   * schedule selected
   */
  public scheduleSelected: TimetableScheduleMerge;
  /**
   * current index schedule
   */
  public currentIndexSchedule: number;

  /**
   * id schedule seleted
   */
  public idScheduleSeleted: number;

  /**
   * id schedule checked;
   */
  public idScheduleChecked: number[] = new Array<number>();
  /**
   * true if isPlay
   */
  public isPlay: boolean = false;
  /**
   * timetables
   */
  public timetables: Timetable[] = new Array<Timetable>();

  /**
   * header timetables
   */
  public headerTimetables: string[] = [];
  /**
   * timetable daily selected
   */
  public timetableDailySelected: TimetableDaily;
  /**
   * schedule daily selected
   */
  public scheduleDailySelected: TimetableScheduleMerge;
  /**
   * current index schedule daily
   */
  public currentIndexScheduleDaily: number;

  /**
   *changeoverOffset
   */
  public changeoverOffset: number;

  /**
   * templateIds;
   */
  public templateIds;

  /**
   * common object
   */
  commonObject: Common;

  public elements: Array<any> = new Array<any>();

  public routes: any = new Array<any>();

  public routeSelected: any;

  public timetablesDaily: TimetableDaily[] = new Array<TimetableDaily>();
  /**
   * timetable schedules daily
   */
  public timetableSchedulesDaily: TimetableScheduleMerge[] = new Array<TimetableScheduleMerge>();
  /**
   * templates
   */
  public templates: Array<Template>;
  /**
   * time date line
   */
  timeDateLine: string;

  /**
   * media setting
   */
  mediaSetting: Media;

  /**
   * sort filter variable
   */
  isSortFilter: boolean;

  /**
   * is sort filter daily
   */
  isSortFilterDaily: boolean = false;

  /**
   * is check all option filter
   */
  isCheckAllOptionFilter: boolean;

  /**
   * column sort filtering
   */
  columnSortFiltering: string;

  /**
   * is show popup sort filter
   */
  isShowPopUpSortFilter: boolean;

  /**
   * last column filter
   */
  lastColumnFilter: string;

  /**
   * list filter display
   */
  listFilterDisplay: Array<OptionFilter>;

  /**
   * list filter display origin
   */
  listFilterDisplayOrigin: Array<OptionFilter>;

  /**
   * is filter
   */
  isFilter: boolean;

  /**
   * is clear
   */
  isClear: boolean;

  /**
   * list current filter
   */
  listCurrentFilter: IHash = {};

  /**
   * list sorted
   */
  listSorted: any = [];

  /**
   * timetables second
   */
  timetablesSecond: any[];

  /**
   * timetables display second
   */
  timetablesDisplaySecond: any[]; //data output with sort filter

  /**
   * header column original second
   */
  headerColumnsOriginalSecond: any[];

  /**
   * data stored second
   */
  dataStoredSecond: any;

  /**
   * name edit
   */
  nameEdit: string;
  /**
   * no edit
   */
  noEdit: string;
  /**
   * suffix edit
   */
  suffixEdit: string;

  /**
   * Sort filter object
   */
  private sortFilterObject: SortFilterObject;

  /**
   * header column show on display
   */
  headerColumns: any = [];
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';
  readonly IS_EDITING_TIMETABLE = 'isEditingTimetable';
  readonly DEFAULT_SUFFIX_MONTHLY = '1';
  /**
   * timetable for display
   */
  timetablesDisplay: Array<Timetable> = new Array<Timetable>();
  /**
   * areas of display template
   */
  areasTimetable: TextArea[];

  /**
   * areas index word of template
   */
  areasIndexWord: Area[];
  readonly TIMETABLE_INDEX = 0;
  readonly INDEX_WORD_INDEX = 1;
  readonly INDEX_TIME_ITEM = 0;
  readonly IS_PREVIEW_ON_SCM = 'isPreviewScheduleMerge';
  /**
   * abort controller request
   */
  abortControllerDisplay: AbortController = new AbortController();
  /**
   * start index schedule
   */
  readonly START_INDEX_SCHEDULE = 0;

  timeoutsDisplay1: any[] = [];
  /**
   * reference position columns
   */
  referencePositionColumnsByTemplate: number[];
  /**
   * template selected
   */
  public templateSelected: Template;

  /**
   * areas drew of template
   */
  areasTimetableDrew: TextArea[];

  /**
   * areas index word drew of template
   */
  areasIndexWordDrew: Area[];
  /**
   * no timetable old
   */
  noTimetableOld: string;

  /**
   * id timetable old
   */
  idTimetableOld: Number;

  /**

  /**
   * header column original
   */
  headerColumnsOriginal: any = [
    { headerName: this.translateService.instant('schedule-merge.no'), property: 'no', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('schedule-merge.suffix'), property: 'suffix', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('schedule-merge.name'), property: 'name', isSortBy: '', isFilterBy: '' }
  ];

  duplicateSetting: any = {};
  /**
   * true if > finish month
   */
  isNextMonth: boolean;
  /**
   * true if < start month
   */
  isPreviousMonth: boolean = true;
  /**
   * current date
   */
  currentDate: Date = new Date();
  /**
   * content days month
   */
  contentDaysMonth: Array<ContentDay>;
  /**
   * year selected
   */
  selectedYear: number;
  /**
   * month selected
   */
  selectedMonth: any;

  areaSwitchingTiming: number;

  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: this.translateService.instant('schedule-merge.month-1'), key: 0 },
    { value: this.translateService.instant('schedule-merge.month-2'), key: 1 },
    { value: this.translateService.instant('schedule-merge.month-3'), key: 2 },
    { value: this.translateService.instant('schedule-merge.month-4'), key: 3 },
    { value: this.translateService.instant('schedule-merge.month-5'), key: 4 },
    { value: this.translateService.instant('schedule-merge.month-6'), key: 5 },
    { value: this.translateService.instant('schedule-merge.month-7'), key: 6 },
    { value: this.translateService.instant('schedule-merge.month-8'), key: 7 },
    { value: this.translateService.instant('schedule-merge.month-9'), key: 8 },
    { value: this.translateService.instant('schedule-merge.month-10'), key: 9 },
    { value: this.translateService.instant('schedule-merge.month-11'), key: 10 },
    { value: this.translateService.instant('schedule-merge.month-12'), key: 11 }
  );

  isRoot: boolean;
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * ElementRef
   */
  @ViewChild('no') noElementRef: ElementRef;
  @ViewChild('suffix') suffixElementRef: ElementRef;
  @ViewChild('name') nameElementRef: ElementRef;
  /**
   * calendars
   */
  calendarsInTwoYear: Array<ContentDay>;

  /**
   * calendar in month
   */
  calendarFromDB: Array<ContentDay> = [];

  /**
   * constant
   */
  Constant = Constant;
  /**
   * userCalendars
   */
  userCalendars: Array<UserCalendar>;
  /**
   * true if every company has status already
   */
  isAlreadyAllCompany: boolean;
  /**
   *
   */
  templateIdSelected: number;

  /**   * selectedUserCalendar
   */
  selectedUserCalendar: UserCalendar;
  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private dataService: DataService,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private scheduleMergeService: ScheduleMergeService,
    private publishTimetableService: PublishTimetableService,
    private templateService: TemplateService,
    private commonTableService: CommonTableService,
    private sortFilterService: SortFilterService,
    private userService: UserService,
    private drawScheduleMergeService: DrawScheduleMergeService,
    private indexWordService: IndexWordService,
    private settingSignageChannelService: SettingSignageChannelService,
    private executingService: ExecutingService,
    private simpleMediaService: SimpleMediaService,
    private scheduleRegistrationService: ScheduleRegistrationService,
    public readonly store: Store<AppState>,
    private customTagService: CustomTagService,
    private timetableService: TimetableService
  ) {
    // subscribe for import
    this.subscriptions.push(
      this.menuActionService.actionImportScheduleMerge.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.importScheduleMerge();
        }
      })
    );
    // subscribe for merge schedule
    this.subscriptions.push(
      this.menuActionService.actionMergeSchedule.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.mergeSchedule();
        }
      })
    );
    // subscribe for export
    this.subscriptions.push(
      this.menuActionService.actionExportTimetable.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.exportTimetable();
        }
      })
    );
    // subscribe for change template
    this.subscriptions.push(
      this.menuActionService.actionChangeTemplate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.showDialogTemplateSetting();
        }
      })
    );
    // subscribe for duplicateSetting
    this.subscriptions.push(
      this.menuActionService.actionDuplicateSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.showDialogDuplicateSetting();
        }
      })
    );
    // setting update timing
    this.subscriptions.push(
      this.menuActionService.actionUpdateTime.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.updateTiming();
        }
      })
    );

    // setting update data
    this.subscriptions.push(
      this.menuActionService.actionUpdateData.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.updateData();
        }
      })
    );

    // add timetables
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.addTimetables();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.editTimetable();
        }
      })
    );
    // setting update text highlight
    this.subscriptions.push(
      this.menuActionService.actionUpdateTextHighlight.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.updateTextHighlightSetting();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.duplicateTimetable();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.deleteTimetable();
        }
      })
    );

    // sort filter timetables
    this.subscriptions.push(
      this.menuActionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.sortFilter();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionSortAndFilterDaily.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.sortFilterDaily();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionChannelAreaPreview.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.signageChannelAreaPreview();
        }
      })
    );
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        // multiple language column header
        this.multiLanguageHeader();
        this.multiLanguageTooltip();
        this.listMonth = [
          { value: this.translateService.instant('schedule-merge.month-1'), key: 0 },
          { value: this.translateService.instant('schedule-merge.month-2'), key: 1 },
          { value: this.translateService.instant('schedule-merge.month-3'), key: 2 },
          { value: this.translateService.instant('schedule-merge.month-4'), key: 3 },
          { value: this.translateService.instant('schedule-merge.month-5'), key: 4 },
          { value: this.translateService.instant('schedule-merge.month-6'), key: 5 },
          { value: this.translateService.instant('schedule-merge.month-7'), key: 6 },
          { value: this.translateService.instant('schedule-merge.month-8'), key: 7 },
          { value: this.translateService.instant('schedule-merge.month-9'), key: 8 },
          { value: this.translateService.instant('schedule-merge.month-10'), key: 9 },
          { value: this.translateService.instant('schedule-merge.month-11'), key: 10 },
          { value: this.translateService.instant('schedule-merge.month-12'), key: 11 }
        ];
        if (
          this.tableSortFilterComponent &&
          this.timetableSelected &&
          this.headerColumnsOriginalSecond &&
          this.headerColumnsOriginalSecond.length > 1
        ) {
          this.tableSortFilterComponent.multiLanguageTooltip();
        }
      })
    );
    this.subscriptions.push(this.store.select(state => state).subscribe());
    this.commonObject = commonService.getCommonObject();
    this.currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
    this.sortFilterObject = sortFilterService.getSortFilterObject();
    this.sortFilterObject.isSortFilter = false;
    this.isRoot = this.commonService.getCommonObject().userIdString == Constant.ROOT;
  }
  async ngOnInit(): Promise<void> {
    await this.getInformationChangeDateLine();
    await this.getAreaSwitching();
    await this.sendTimezoneToBack();
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_DUPLICATE_SETTING).subscribe(data => {
      if (data) {
        this.duplicateSetting = JSON.parse(data.value);
      }
    });
    this.getAllSortFilterConditions();
    await this.getAllRoute();
    this.getAllScheduleMerge();
    this.executingService.executing();
    await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
    this.executingService.executed();
    this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.SCHEDULE_MERGE).subscribe(settingSignageChannel => {
      if (!settingSignageChannel || (!settingSignageChannel.folderId && !settingSignageChannel.mediaId)) {
        return;
      }
      this.simpleMediaService.getMediaById(settingSignageChannel.mediaId).subscribe(
        async mediaData => {
          if (!mediaData) {
            this.mediaSetting = undefined;
            return;
          }
          this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(mediaData, false));
          this.drawScheduleMergeService.setupPreview(this.timetableSelected, this.mediaSetting ?? undefined);
          // draw area is set(signage channel)
          this.drawScheduleMergeService.drawAreasSignageChannel(this.templateSelected, this.renderer);
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    });
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPDATE_TIMING_SCHEDULE_MERGE).subscribe(data => {
      this.changeoverOffset = data ? data.value : Constant.UPDATE_TIMING_DEFAULT;
    });
    this.getAllSettingTemplateIds();
    this.dataStoredSecond = {};
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
          this.calendarsInTwoYear,
          this.selectedMonth,
          this.selectedYear,
          this.commonObject
        );
        this.currentDate = new Date(Helper.getCurrentDateByTimeZone(this.commonObject));
      }
    });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (data[1]) {
          this.sendTimezoneToBack();
        }
      }
    });
    this.dataService.sendData([Constant.IS_ROOT, this.isRoot]);
  }
  /**
   * get All ScheduleMerge
   */
  private getAllScheduleMerge(): void {
    this.scheduleMergeService.getAllScheduleMerges().subscribe(timetables => {
      if (!timetables.length) {
        return;
      }
      this.timetables = Helper.convertTimetableAfterImport(timetables, this.timeDateLine);
      this.timetables.forEach(timetable => {
        timetable?.schedules?.forEach(schedule => {
          schedule.contentDays = this.getCalendarsForSchedule(timetable?.no);
          if (schedule.contentDays.length) {
            const index = schedule.contentDays.findIndex(day => day.isOtherMonth == false);
            let indexActive = 0;
            for (let i = index; i < index + Helper.getDaysInMonth(timetable?.no); i++) {
              schedule.contentDays[i].isActive = schedule.isActiveDays[indexActive] ? true : false;
              if (schedule?.isEditDays != null) {
                schedule.contentDays[i].isEdit = schedule?.isEditDays[indexActive] ? true : false;
              }
              indexActive++;
            }
          }
        });
        this.timetablesDisplay = [...timetables];
        if (!this.isSortFilter) {
          this.selectTimetable(this.timetables[Constant.FIRST_ELEMENT_INDEX], null);
          return;
        }
        // case filter
        if (!_.isEmpty(this.listCurrentFilter)) {
          this.filterTimetableFirstTime();
        }
        // case sort
        if (this.listSorted[Constant.SORT_COLUMN_INDEX]) {
          this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        }
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      });
      this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
      this.multiLanguageTooltip();
    });
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.drawScheduleMergeService.clearAllThreadDrawTemplate(this.templateSelected);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * choose tab
   * @param tabEnum
   */
  public chooseTab(tabEnum: Tab_Enum): void {
    let tabSelectedOld = _.cloneDeep(this.tabSelected);
    this.tabSelected = tabEnum;
    this.isPlay = false;
    this.dataService.sendData([Constant.IS_TAB_DAILY, tabEnum == Tab_Enum.DAILY]);
    this.dataService.sendData([Constant.IS_TAB_MONTHLY, tabEnum == Tab_Enum.MONTHLY]);
    this.dataService.sendData([Constant.IS_TAB_CONFIRMATION, tabEnum == Tab_Enum.CONFIRMATION]);
    this.dataService.sendData([this.IS_PREVIEW_ON_SCM, this.isPlay]);
    switch (tabEnum) {
      case Tab_Enum.MONTHLY:
        this.drawScheduleMergeService.pausePreview();
        this.drawScheduleMergeService.clearAllThreadDrawTemplate(this.templateSelected);
        break;
      case Tab_Enum.DAILY:
        if (tabSelectedOld != Tab_Enum.CONFIRMATION) {
          this.clearDailySchedule();
          this.createDailySchedule();
        } else {
          this.selectTimetableDaily(this.timetablesDaily[0]);
        }
        this.drawDisplay(this.templateSelected);
        break;
      case Tab_Enum.CONFIRMATION:
        this.drawScheduleMergeService.pausePreview();
        this.drawScheduleMergeService.clearAllThreadDrawTemplate(this.templateSelected);
        break;
      default:
        break;
    }
  }

  clearDailySchedule(): void {
    this.timetablesDaily = undefined;
    this.timetablesDailySecond = undefined;
    this.timetablesDailyDisplaySecond = undefined;
    this.headerColumnsDailyOriginalSecond = undefined;
    this.timetableDailySelected = undefined;
  }
  /**
   * enlarge preview
   */
  public enlargePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    if (this.isEnlargePreview && this.tabSelected == Tab_Enum.DAILY && this.templateSelected) {
      this.panzoomDisplay = undefined;
      const areasDisplay1 = Helper.getAllAreaTemplate(this.templateSelected);
      this.reDrawPreview(areasDisplay1);
    }
  }

  // ============ ImplementTab 月別運行予定表 ============
  /**
   * select timetable
   * @param timetable
   * @returns
   */
  public selectTimetable(timetable: any, event: any): void {
    if (timetable == this.timetableSelected) {
      return;
    }
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
    if (event?.target?.id === 'checkBoxTimetableMerge' || timetable == this.timetableSelected) {
      return;
    }
    this.timetableSelected = timetable;
    this.convertDataSchedule(this.timetableSelected);
    this.currentIndexSchedule = 0;

    if (!this.timetableSelected?.schedules?.length) {
      this.scheduleSelected = undefined;
      return;
    }
    this.multiLanguageTooltip();
    this.getAllSettingTemplateIds();
    this.selectSchedule(this.currentIndexSchedule, null, null);
  }

  /**
   * convert data schedule
   */
  public convertDataSchedule(timetableSelected: any): void {
    this.headerColumnsOriginalSecond = [];
    this.timetablesSecond = [];
    const headerColumnsOriginalSecond = [];
    const timetablesSecond = [];

    headerColumnsOriginalSecond.push({ headerName: '', property: 'isChecked', type: 'checkbox' });

    timetableSelected?.headers?.forEach((e, index) => {
      headerColumnsOriginalSecond.push({
        headerName: e,
        property: index + '',
        isSortBy: '',
        isFilterBy: '',
        isHaveSortOrder: true,
        isChecked: false
      });
    });
    timetableSelected?.schedules?.forEach((e, index) => {
      timetablesSecond.push({
        id: index,
        indexFilter: index,
        ...Object.assign({}, e.columnsData),
        invalid: e.inValidRow,
        isChecked: e.isChecked
      });
    });
    this.timetablesDisplaySecond = this.timetablesSecond;
    this.headerColumnsOriginalSecond = headerColumnsOriginalSecond;
    this.timetablesSecond = timetablesSecond;

    if (headerColumnsOriginalSecond.length == 1 || timetablesSecond.length == 0) {
      this.dataStoredSecond = null;
    }
  }
  /**
   * get calendars for schedule
   * @returns
   */
  private getCalendarsForSchedule(no: String): Array<ContentDaySchedule> {
    let month = +no.substring(2) - 1;
    let year = +`20${no.substring(0, 2)}`;
    let calendars = Helper.getCalendarsScheduleMerge(year, month);
    return Helper.getCalendarsByMonthYearSchedule(calendars, month, year, this.commonObject);
  }

  /**
   * change checked
   * @param timetableId
   * @param event
   */
  public changeChecked(timetableId: number, event): void {
    event.stopPropagation();
    let index = this.timetables.findIndex(timetable => timetable.id === timetableId);
    this.timetables[index].isChecked = !this.timetables[index].isChecked;
    let indexDisplay = this.timetablesDisplay.findIndex(timetable => timetable.id === timetableId);
    this.timetablesDisplay[indexDisplay].isChecked = this.timetables[index].isChecked;
    if (this.timetables[index].id == this.timetableSelected?.id) {
      this.timetableSelected.isChecked = this.timetables[index].isChecked;
    }
  }

  /**
   * change checked
   * @param timetableId
   * @param event
   */
  public changeCheckedSchedule(index, event): void {
    event.stopPropagation();
    this.timetableSelected.schedules[index].isChecked = !this.timetableSelected.schedules[index].isChecked;
    if (this.timetableSelected.schedules[index] == this.scheduleSelected) {
      this.timetableSelected.schedules[index].isChecked = this.scheduleSelected.isChecked;
    }
    if (this.timetableSelected.schedules[index].isChecked) {
      this.idScheduleChecked.push(index);
    } else {
      let indexInCheckedIds = this.idScheduleChecked.findIndex(id => id == index);
      this.idScheduleChecked.splice(indexInCheckedIds, 1);
    }
  }

  /**
   * selectScheduleFromSortFilter
   * @param index
   */
  public selectScheduleFromSortFilter(dataRow: any, dataRowSchedule): void {
    if (dataRow) {
      this.selectSchedule(dataRow.indexFilter, null, dataRowSchedule);
    }
  }
  /**
   * select schedule
   */
  public selectSchedule(index: number, event, dataRowSchedule?: any): void {
    if (index < 0 || index >= this.timetableSelected?.schedules?.length) {
      return;
    }
    // if (event?.target?.id === 'checkboxSchedule' || this.timetableSelected?.schedules[index] == this.scheduleSelected) { //doing in tableSortFilterComponent
    //   return;
    // }
    if ((index || index === 0) && index < this.timetablesDisplaySecond.length) {
      this.scheduleSelected = this.timetableSelected?.schedules[this.timetablesDisplaySecond[index].id];
      this.currentIndexSchedule = index;
    }
    if (dataRowSchedule) {
      this.scheduleSelected = this.timetableSelected?.schedules[dataRowSchedule.id];
      this.currentIndexSchedule = index;
      this.idScheduleSeleted = dataRowSchedule.id;
    }
  }

  /**
   * select day
   * @param {ContentDaySchedule} day selected day
   */
  public selectDay(contentDay: ContentDaySchedule): void {
    if (contentDay.isOtherMonth || contentDay.inactive) {
      return;
    }
    this.selectedDay = contentDay;
    const schedulesChecked = this.timetableSelected.schedules.filter(schedule => schedule.isChecked);
    let selectDaysIndex = new Array();
    if (schedulesChecked.length) {
      if (!this.scheduleSelected.isChecked) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.check-selected-schedule')
          }
        });
        return;
      }
      const calendarUpdate = !contentDay.isActive;
      schedulesChecked.forEach(scheduleChecked => {
        scheduleChecked.contentDays
          .filter(item => item.fullDate.getTime() == contentDay.fullDate.getTime())
          .forEach(data => {
            data.isActive = calendarUpdate;
            data.isEdit = true;
          });
      });
      selectDaysIndex = [...this.idScheduleChecked];
    } else {
      contentDay.isActive = !contentDay.isActive;
      contentDay.isEdit = true;
      selectDaysIndex.push(this.idScheduleSeleted);
    }
    this.scheduleMergeService
      .saveScheduleEdit(this.selectedDay.fullDate.getDate(), this.timetableSelected.id, this.selectedDay.isActive, selectDaysIndex)
      .subscribe();
  }

  /**
   * change state preview
   */
  public changeStatePreview(): void {
    if (!this.templateSelected) {
      return;
    }
    this.isPlay = !this.isPlay;
    this.drawScheduleMergeService.changeStatePlayPause(this.isPlay);
    this.drawScheduleMergeService.changeStartState(false);
    this.dataService.sendData([this.IS_PREVIEW_ON_SCM, this.isPlay]);
    this.changeDetectorRef.detectChanges();
  }

  // ============ ImplementTab 日別運行予定表 ============
  /**
   * select timetable daily
   * @param timetable
   * @returns
   */
  public selectTimetableDaily(timetable: any): void {
    if (timetable == this.timetableDailySelected) {
      return;
    }
    if (this.isPlay) {
      this.changeStatePreview();
    } else {
      this.drawScheduleMergeService.pausePreview();
    }
    this.timetableDailySelected = timetable;
    this.currentIndexScheduleDaily = 0;
    this.convertDataScheduleDaily(timetable);
    // if (!this.timetablesDailyDisplaySecond.length) {
    const areasDisplay1 = Helper.getAllAreaTemplate(this.templateSelected);
    this.reDrawPreview(areasDisplay1);
    // }
    this.selectScheduleDaily(this.currentIndexScheduleDaily, true);
  }
  timetablesDailySecond: any[];
  timetablesDailyDisplaySecond: any[]; //data output with sort filter
  headerColumnsDailyOriginalSecond: any[];
  dataStoredDailySecond: any;

  convertDataScheduleDaily(timetableDailySelected: any) {
    this.dataStoredDailySecond = null;

    this.headerColumnsDailyOriginalSecond = [];
    this.timetablesDailySecond = [];
    timetableDailySelected?.headers?.forEach((e, index) => {
      this.headerColumnsDailyOriginalSecond.push({
        headerName: e,
        property: index + '',
        isSortBy: '',
        isFilterBy: '',
        isHaveSortOrder: true,
        isChecked: false
      });
    });
    timetableDailySelected?.schedules?.forEach((e, index) => {
      this.timetablesDailySecond.push({
        id: index,
        indexFilter: index,
        ...Object.assign({}, e.columnsData),
        invalid: e.inValidRow,
        invalidDuplicate: e.inValidRowDulicate
      });
    });
    this.timetablesDailyDisplaySecond = this.timetablesDailySecond;
  }

  /**
   * selectScheduleDailyFromSortFilter
   * @param index
   */
  public selectScheduleDailyFromSortFilter(dataRow: any): void {
    if (dataRow) {
      this.selectScheduleDaily(dataRow.indexFilter);
    }
  }
  /**
   * select schedule daily
   * @param index
   */
  public selectScheduleDaily(index: number, isReset?: boolean): void {
    if (index < 0 || !this.timetablesDailyDisplaySecond || index >= this.timetablesDailyDisplaySecond?.length) {
      return;
    }
    this.scheduleDailySelected = this.timetablesDailyDisplaySecond[index];
    this.currentIndexScheduleDaily = index;
    const areasDisplay1 = Helper.getAllAreaTemplate(this.templateSelected);
    // setup AbortController
    this.abortControllerDisplay.abort();
    this.abortControllerDisplay = new AbortController();
    this.clearBeforeDrawFinishSchedule();
    if (index == this.timetablesDailyDisplaySecond.length) {
      // finish timetable
      this.drawScheduleMergeService.handleUnSubscriptionForLayer(this.templateSelected);
      this.currentIndexScheduleDaily = this.timetablesDailyDisplaySecond.length + 1;
      // clear before drawing
      this.clearBeforeDrawFinishSchedule();
      // draw area is timing on
      this.drawScheduleMergeService.drawPreviewFixAreaTimingOn(
        areasDisplay1?.filter(area => area.isFix && area.isTimingOn),
        this.renderer,
        true,
        this.templateSelected
      );
      return;
    }
    if (index === this.START_INDEX_SCHEDULE) {
      this.currentIndexScheduleDaily = this.START_INDEX_SCHEDULE;
      // pause if reset when playing
      if (isReset && this.isPlay) {
        this.reDrawPreview(areasDisplay1);
        return;
      }
    } else {
      if (this.currentIndexScheduleDaily === this.timetablesDailyDisplaySecond.length + 1) {
        // clear Fix Area Timing On
        this.clearFixAreaTimingOn(areasDisplay1);
      }
      this.currentIndexScheduleDaily = index;
    }
    if (isReset) {
      this.reDrawPreview(areasDisplay1);
      return;
    }
    // draw time table
    this.drawScheduleMergeService.setDataTimetables(
      this.currentIndexScheduleDaily,
      this.referencePositionColumnsByTemplate,
      this.timeDateLine
    );
    this.drawScheduleMergeService.setDataPreviewScheduleMerge(this.timetablesDailyDisplaySecond);
    this.drawScheduleMergeService.clearAreas(this.areasTimetableDrew);
    this.drawScheduleMergeService.clearAreas(this.areasIndexWordDrew);
    this.drawScheduleMergeService.clearAreas(this.areasTimetable);
    this.drawScheduleMergeService.clearAreas(this.areasIndexWord);
    const areasTimetableDraw = _.cloneDeep(this.areasTimetable);
    this.drawAreasTimetableForDisplay(this.templateSelected, areasTimetableDraw);
    this.areasTimetableDrew = areasTimetableDraw;
    this.drawScheduleMergeService.clearMediaIndexWords();
    // draw index word
    if (this.areasIndexWord?.length) {
      // get index word from schedule
      const areasIndexWord = _.cloneDeep(this.areasIndexWord);
      this.indexWordService.getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWord)).subscribe(indexWords => {
        Helper.setDataIndexWordForAreasMerge(areasIndexWord, indexWords, this.timetablesDailyDisplaySecond, this.currentIndexScheduleDaily);
        this.drawScheduleMergeService.setDataPreviewScheduleMerge(this.timetablesDailyDisplaySecond, null, null, areasIndexWord);
        // draw index word
        this.drawAreasIndexWordForDisplay(this.templateSelected, areasIndexWord, indexWords);
        this.areasIndexWordDrew = areasIndexWord;
      });
    }
  }
  /**
   * Re draw preview
   * @param areasDisplay1
   * @param areasDisplay2
   */
  private reDrawPreview(areasDisplay1: Area[]): void {
    this.drawScheduleMergeService.changeStartState(true);
    this.isPlay = false;
    this.dataService.sendData([this.IS_PREVIEW_ON_SCM, this.isPlay]);
    this.changeDetectorRef.detectChanges();
    // clear time out
    this.drawScheduleMergeService.clearTimeoutsStopDurationArea(true);
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearAllDrawThread();
    // clear Fix Area Timing On
    this.clearFixAreaTimingOn(areasDisplay1);
    this.drawDisplay(this.templateSelected);
    this.drawScheduleMergeService.changeStatePlayPause(this.isPlay);
  }

  /**
   * clear Fix Area Timing On
   * @param areasDisplay1
   * @param areasDisplay2
   */
  private clearFixAreaTimingOn(areasDisplay1: Array<Area>): void {
    this.drawScheduleMergeService.clearFixAreaTimingOn(areasDisplay1?.filter(area => area.isFix && area.isTimingOn));
  }

  /**
   * clear all time out
   * @param timeouts
   */
  private clearTimeoutDisplay(timeouts: any[]): void {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  }

  /**
   * Clear all draw thread
   */
  private clearAllDrawThread(): void {
    if (this.templateSelected) {
      this.drawScheduleMergeService.clearAllThreadDrawTemplate(this.templateSelected);
    }
  }
  /**
   * clear before draw finish schedule
   */
  private clearBeforeDrawFinishSchedule(): void {
    this.drawScheduleMergeService.clearAreas(this.areasTimetableDrew);
    this.drawScheduleMergeService.clearAreas(this.areasTimetable);
  }
  /**
   * choose tool zoom, pan
   *
   * @param tool
   */
  public chooseTool(tool: PreviewToolEnum): void {
    if (tool == PreviewToolEnum.PAN) {
      this.isPan = !this.isPan;
      this.renderer.setStyle(this.divContainCanvas?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
    } else {
      this.isZoom = !this.isZoom;
      this.renderer.setStyle(this.divContainCanvas?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
    }
    if (this.isZoom && this.isPan) {
      this.renderer.setStyle(this.divContainCanvas?.nativeElement, 'cursor', 'move');
    }
    this.panzoomDisplay.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
  }

  /**
   * change template
   * @param displayId
   */
  public changeTemplate(): void {
    this.drawScheduleMergeService.clearAllThreadDrawTemplate(this.templateSelected);
    const display = this.templates.find(item => item.id == this.templateIdSelected);
    this.templateSelected = display;
    if (!this.templateSelected) {
      this.isPlay = false;
      this.drawScheduleMergeService.pausePreview();
      Helper.clearNodeChild(this.divContainCanvas?.nativeElement);
      this.dataService.sendData([this.IS_PREVIEW_ON_SCM, this.isPlay]);
      return;
    }
    this.referencePositionColumnsByTemplate = Helper.getReferencePositionColumnsByTemplate(this.templateSelected);
    this.referencePositionColumnsByTemplate = this.fillDataForReferencePositionColumn();
    if (this.isPlay) {
      this.changeStatePreview();
    }
    this.drawDisplay(display);
  }

  /**
   * calculate scale transform canvas
   *
   * @param template
   */
  private calculateScaleTransformCanvas(template: Template): void {
    if (!template) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const maxHeight = this.divPreviewDisplay.nativeElement.clientHeight - 80;
    const maxWidth = this.divPreviewDisplay.nativeElement.clientWidth;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (template.width > maxWidth) {
      scaleTransform.scaleX = maxWidth / template.width;
    }
    if (template.height > maxHeight) {
      scaleTransform.scaleY = maxHeight / template.height;
    }
    const scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(this.divContainCanvas.nativeElement, 'transform', 'scale(' + scale + ')');
    const realHeightTemplate = template.height * scale;
    const realWidthTemplate = template.width * scale;
    const height = (maxHeight - realHeightTemplate) / 2;
    const width = (maxWidth - realWidthTemplate) / 2;
    this.divContainCanvas.nativeElement.style.marginTop = height + 'px';
    this.divContainCanvas.nativeElement.style.marginLeft = width + 'px';
    if (this.panzoomDisplay) {
      this.panzoomDisplay.reset({
        startScale: scale,
        minScale: 0.1,
        maxScale: 2,
        startX: 0,
        startY: 0
      });
    } else {
      this.panzoomDisplay = Panzoom(this.divContainCanvas?.nativeElement, { startScale: scale, minScale: 0.1, maxScale: 2 });
    }
    this.panzoomDisplay.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
  }

  /**
   * subscribe event mouse wheel
   *
   * @param e
   * @returns
   */
  @HostListener('mousewheel', ['$event'])
  mouseWheel(e) {
    if (!this.isZoom) {
      return;
    }
    if (e.target.id.includes('schedule-previewCanvas')) {
      this.panzoomDisplay.zoomWithWheel(e);
    }
  }

  /**
   * draw display template
   * @param display Template
   */
  private drawDisplay(display: Template): void {
    this.changeDetectorRef.detectChanges();
    const canvasDisplayNode = this.divContainCanvas?.nativeElement;
    // clear node child
    Helper.clearNodeChild(canvasDisplayNode);
    if (!display) {
      return;
    }
    let areaTimetableAndIndexWord = Helper.getAllAreaReferenceTimetableAndIndexWord(display);

    this.areasTimetable = areaTimetableAndIndexWord[this.TIMETABLE_INDEX];
    this.areasIndexWord = areaTimetableAndIndexWord[this.INDEX_WORD_INDEX];
    this.drawScheduleMergeService.clearIntervalsClock();
    if (canvasDisplayNode) {
      // draw
      this.createCanvasTemplate(display, this.renderer);
      this.drawScheduleMergeService.createAllCanvasAreaTemplate(display, this.divContainCanvas, this.renderer);
      this.calculateScaleTransformCanvas(display);
      if (this.isPan) {
        this.renderer.setStyle(this.divContainCanvas.nativeElement, 'cursor', 'move');
      } else {
        this.renderer.setStyle(this.divContainCanvas.nativeElement, 'cursor', 'default');
      }
      this.drawScheduleMergeService.setupPreview(this.timetableDailySelected, this.mediaSetting ?? undefined);
      let timetableSchedule = this.timetablesDailyDisplaySecond;
      this.drawScheduleMergeService.resetData();
      if (timetableSchedule) {
        let areasTimetableDraw = _.cloneDeep(this.areasTimetable);
        if (areasTimetableDraw?.length) {
          // draw time table
          this.referencePositionColumnsByTemplate = Helper.getReferencePositionColumnsByTemplate(display);
          this.referencePositionColumnsByTemplate = this.fillDataForReferencePositionColumn();
          this.drawScheduleMergeService.setDataTimetables(
            this.currentIndexScheduleDaily,
            this.referencePositionColumnsByTemplate,
            this.timeDateLine
          );
          this.drawScheduleMergeService.setDataPreviewScheduleMerge(timetableSchedule);
        }
        let areasIndexWordDraw = _.cloneDeep(this.areasIndexWord);
        if (areasIndexWordDraw?.length) {
          this.indexWordService.getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWordDraw)).subscribe(indexWords => {
            Helper.setDataIndexWordForAreasMerge(
              areasIndexWordDraw,
              indexWords,
              this.timetablesDailyDisplaySecond,
              this.currentIndexScheduleDaily
            );
            this.drawScheduleMergeService.setDataPreviewScheduleMerge(timetableSchedule, null, null, areasIndexWordDraw);
            this.drawScheduleMergeService.drawPreview(display, this.renderer);
            this.drawScheduleMergeService.changeStatePlayPause(this.isPlay);
          });
        } else {
          this.drawScheduleMergeService.drawPreview(display, this.renderer);
        }
      } else {
        this.drawScheduleMergeService.drawPreview(display, this.renderer);
      }
    }
  }
  /**
   * Fill data for reference position column
   *
   * @returns
   */
  private fillDataForReferencePositionColumn(): number[] {
    let newReferencePositionColumnsByTemplate = [];
    const lastReferencePositionColumn = this.referencePositionColumnsByTemplate[this.referencePositionColumnsByTemplate?.length - 1];
    for (let index = 0; index <= lastReferencePositionColumn; index++) {
      newReferencePositionColumnsByTemplate.push(index);
    }
    return _.sortBy(newReferencePositionColumnsByTemplate);
  }
  /**
   * get list name index word and list group index word
   * @param areas
   * @returns list name index word and list group index word
   */
  private getParamForGetIndexWord(areas: Area[]): any {
    let listNameIndexWord = [];
    let listGroupId = [];
    if (!this.referencePositionColumnsByTemplate?.length) {
      return [listGroupId, listNameIndexWord];
    }
    areas.forEach(area => {
      let nameIndexWord = Helper.getNameIndexWordFromScheduleMerge(
        this.timetablesDailyDisplaySecond,
        area,
        this.currentIndexScheduleDaily,
        this.referencePositionColumnsByTemplate.findIndex(reference => area.referencePositionColumn + 1 === reference)
      );
      listGroupId.push(area.indexWordGroupId);
      listNameIndexWord.push(nameIndexWord);
    });
    return [listGroupId, listNameIndexWord];
  }
  /**
   * create canvas template
   * @param template template
   * @param renderer
   */
  public createCanvasTemplate(template: Template, renderer: Renderer2): void {
    const canvas = renderer.createElement('canvas');
    canvas.id = 'schedule-previewCanvas';
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(this.divContainCanvas.nativeElement, canvas);
  }

  // ============ Implement menu Setting ============
  public showDialogTemplateSetting(): void {
    let areaGroup;
    if (this.tabSelected == Tab_Enum.CONFIRMATION) {
      if (!this.routeSelected) {
        return;
      }
      areaGroup = this.elements.find(e => e.id == this.routeSelected.elementId);
    } else {
      if (!this.timetableSelected) {
        return;
      }
      const regex = Constant.REGEX_TIMETABLE_DAILY;
      const match = this.timetableSelected?.name.match(regex);
      let areaGroupName;
      if (match && match[1]) {
        areaGroupName = match[1];
      } else {
        return;
      }
      areaGroup = this.elements.find(e => e.name == areaGroupName);
    }
    this.dialogService.showDialog(
      DialogTemplateSettingScheduleComponent,
      {
        data: {
          isScheduleRegistration: false,
          routeId: areaGroup.id
        }
      },
      result => {
        if (result == undefined) {
          return;
        }
        this.templateIds = result;
        this.getTemplateByTemplateIds(this.templateIds);
        if (this.tabSelected == Tab_Enum.DAILY) {
          this.templateIdSelected = -1;
          this.changeTemplate();
        }
      }
    );
  }

  public showDialogDuplicateSetting(): void {
    let duplicateSettingClone = _.cloneDeep(this.duplicateSetting);
    duplicateSettingClone.column4 = Helper.reEncodeHTML(this.duplicateSetting?.column4);
    this.dialogService.showDialog(
      DialogDuplicateSettingComponent,
      {
        data: {
          properties: this.headerColumnsOriginalSecond?.map(header => header.headerName),
          duplicateSetting: duplicateSettingClone
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.duplicateSetting = result;
      }
    );
  }

  /**
   * import Schedule Merge
   */
  public importScheduleMerge(): void {
    let element = document.getElementById('importedFileSchedule') as HTMLInputElement;
    element.setAttribute('accept', '.xlsx');
    element.click();
  }
  /**
   * upload excel
   * @param event
   */
  public uploadExcel(event): Promise<void> {
    let selectedFiles: File[] = event.target.files;
    const typeFiles = ['xlsx'];
    for (const file of selectedFiles) {
      let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (!typeFiles.includes(typeName)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.invalid-file')
          }
        });
        return;
      }
    }
    this.scheduleMergeService.readExcelsFromClient(selectedFiles, this.getRegexTime(), this.timeDateLine).subscribe(
      timetables => {
        this.handleAfterGetTimetables(timetables, 'add');
        this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
      },
      error => {
        this.handleShowErrorMessageFromServerWhenImport(error);
      }
    );
    // reset input file value
    let element = document.getElementById('importedFileSchedule') as HTMLInputElement;
    element.value = null;
  }
  /**
   * create daily schedule
   */
  @ViewChild('monthlyTab') myDiv: ElementRef<HTMLElement>;
  private async createDailySchedule() {
    let areaGroupName = this.extractNameFromFormat(this.timetableSelected.name);
    this.dialogService.showDialog(
      DialogCreateDailyScheduleComponent,
      {
        data: {
          timetableSelected: this.timetableSelected,
          timeDateLine: this.timeDateLine,
          duplicateSetting: this.duplicateSetting,
          areaGroupName: areaGroupName
        }
      },
      result => {
        this.timetablesDaily = result;
        this.selectTimetableDaily(this.timetablesDaily[0]);
        this.selectedDay = undefined;
      }
    );
  }

  /**
   * extractNameFromFormat
   * @param inputString
   * @returns
   */
  private extractNameFromFormat(inputString: string): string {
    const regex = /_(.*?)_/;
    const matches = regex.exec(inputString);
    const name = matches && matches.length > 1 ? matches[1] : '';

    return name;
  }

  /**
   * checkDataMonthly
   * @returns
   */
  private checkDataMonthly(): Boolean {
    return this.timetableSelected?.schedules?.some(schedule => schedule.inValidRow);
  }
  @ViewChild('confirmationTab') confirmationTab: ElementRef<HTMLElement>;
  public beforeChooseTabDaily(): void {
    if (this.tabSelected == Tab_Enum.CONFIRMATION) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.select-timetable-tab-monthly')
          }
        },
        result => {
          let el: HTMLElement = this.confirmationTab.nativeElement;
          el.click();
          el.focus();
        }
      );
      return;
    } else {
      if (!this.timetables || !this.timetables.length) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('schedule-merge.msg.select-timetable-tab-monthly')
            }
          },
          result => {
            let el: HTMLElement = this.myDiv.nativeElement;
            el.click();
            el.focus();
          }
        );

        return;
      }
      this.showDialogConfirmCreate();
    }
  }

  /**
   * showDialogConfirmCreate
   * @returns
   */
  private showDialogConfirmCreate(): void {
    if (this.tabSelected != Tab_Enum.MONTHLY) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant(`schedule-merge.msg.confirm-create-daily`),
          button1: this.translateService.instant('schedule-merge.msg.yes'),
          button2: this.translateService.instant('schedule-merge.msg.no')
        }
      },
      result => {
        if (!result) {
          let el: HTMLElement = this.myDiv.nativeElement;
          el.click();
          el.focus();
          return;
        } else {
          if (this.checkDataMonthly() && this.tabSelected == Tab_Enum.MONTHLY) {
            this.dialogService.showDialog(
              DialogMessageComponent,
              {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('schedule-merge.msg.data-invalid')
                }
              },
              result => {
                let el: HTMLElement = this.myDiv.nativeElement;
                el.click();
                el.focus();
              }
            );
            return;
          } else {
            this.chooseTab(Tab_Enum.DAILY);
          }
        }
      }
    );
  }

  /**
   * getNameDaily
   * @param no
   * @param day
   * @returns
   */
  public getNameDaily(no: string, day: number): string {
    let year = no.substring(0, 2);
    let month = no.substring(2, 4);
    let dayString = (day + '').padStart(2, '0');
    let name = `20${year}年${month}月${dayString}日_${Constant.LAST_NAME_DAILY_SCHEDULE}`;
    return name;
  }
  /**
   * getColumDataDaily
   * @param columnDataTimetables
   * @param day
   * @return
   */
  private getColumDataDaily(timetable: Timetable, day: number): any {
    return timetable.schedules?.filter(schedule => {
      let index = schedule.contentDays.findIndex(item => item.isOtherMonth == false);
      return index != -1 && schedule.contentDays[index + day].isActive;
    });
  }
  /**
   * convert Timetables To Timetable Daily
   */
  private convertTimetablesToTimetableDaily(): void {
    this.timetablesDaily = new Array<TimetableDaily>();
    if (!this.timetableSelected) {
      return;
    }
    let noTimetable = this.timetableSelected.no;
    let dayInsMonth = Helper.getDaysInMonth(noTimetable);
    for (let i = 0; i < dayInsMonth; i++) {
      let timetableDaily: TimetableDaily = new TimetableDaily();
      timetableDaily.id = i;
      timetableDaily.no = this.timetableSelected.no;
      timetableDaily.suffix = `${i + 1}`;
      timetableDaily.name = this.getNameDaily(this.timetableSelected.no, i + 1);
      timetableDaily.headers = this.timetableSelected.headers;
      timetableDaily.schedules = this.getColumDataDaily(_.cloneDeep(this.timetableSelected), i);
      this.timetablesDaily.push(timetableDaily);
    }
  }

  /**
   * setting update timing
   */
  public updateTiming(): void {
    if (this.isPlay || this.timetableSelected?.isEdit) {
      return;
    }
    forkJoin({
      commonTable: this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPDATE_TIMING_SCHEDULE_MERGE),
      commonTableIsBack: this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPDATE_TIMING_SCHEDULE_MERGE_IS_BACK)
    }).subscribe(
      data => {
        this.dialogService.showDialog(
          DialogTimetableUpdateTimingComponent,
          {
            data: {
              commonTable:
                data.commonTable ?? new CommonTable(Constant.KEY_UPDATE_TIMING_SCHEDULE_MERGE, `${Constant.UPDATE_TIMING_DEFAULT}`),
              commonTableIsBack: data.commonTableIsBack ?? new CommonTable(Constant.KEY_UPDATE_TIMING_SCHEDULE_MERGE_IS_BACK, `true`)
            }
          },
          result => {
            if (result == undefined) {
              return;
            }
            this.changeoverOffset = result;
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * updateData
   */
  public updateData(): void {
    if (this.tabSelected != Tab_Enum.DAILY || !this.timetablesDaily.length) {
      return;
    }
    if (!this.templateIds) {
      this.templateIds = [...new Set()];
    }
    if (this.checkDataDailyBeforeUpdate('inValidRow')) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-merge.msg.data-invalid')
        }
      });
      return;
    }
    if (this.checkDataDailyBeforeUpdate('inValidRowDulicate')) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant(`schedule-merge.msg.confirm-update-data`),
            button1: this.translateService.instant('schedule-merge.msg.yes'),
            button2: this.translateService.instant('schedule-merge.msg.cancel')
          }
        },
        result => {
          if (!result) {
            return;
          }
          let areaGroupName = this.extractNameFromFormat(this.timetableSelected.name);
          this.publishTimetableService
            .publishDataUpdateScheduleMerge(this.changeoverOffset, this.templateIds, this.timeDateLine, areaGroupName)
            .subscribe(
              () => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-confirm.title'),
                    text: this.translateService.instant('schedule-merge.msg.update-success')
                  }
                });
                this.timetableSelected?.schedules?.filter(schedule => {
                  schedule.contentDays?.filter(contentDay => {
                    contentDay.isEdit = false;
                  });
                });
                if (this.timetableSelected.id) {
                  this.scheduleMergeService.saveTimetableAfterUpdate(this.timetableSelected.id).subscribe();
                }
                this.scheduleMergeService.saveHeadersToOperation(areaGroupName).subscribe();
              },
              error => {
                this.handleError(error);
              }
            );
        }
      );
    } else {
      let areaGroupName = this.extractNameFromFormat(this.timetableSelected.name);
      this.publishTimetableService
        .publishDataUpdateScheduleMerge(this.changeoverOffset, this.templateIds, this.timeDateLine, areaGroupName)
        .subscribe(
          () => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-confirm.title'),
                text: this.translateService.instant('schedule-merge.msg.update-success')
              }
            });
            this.timetableSelected?.schedules?.filter(schedule => {
              schedule.contentDays?.filter(contentDay => {
                contentDay.isEdit = false;
              });
            });
            if (this.timetableSelected.id) {
              this.scheduleMergeService.saveTimetableAfterUpdate(this.timetableSelected.id).subscribe();
            }
            this.scheduleMergeService.saveHeadersToOperation(areaGroupName).subscribe();
          },
          error => {
            this.handleError(error);
          }
        );
    }
  }

  /**
   * checkDataDailyBeforeUpdate
   * @returns
   */
  private checkDataDailyBeforeUpdate(typeError: string): Boolean {
    for (const timetable of this.timetablesDaily) {
      if (!timetable || !timetable.schedules || !timetable.schedules.length) {
        continue;
      }
      for (const schedule of timetable.schedules) {
        if (schedule?.[typeError]) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * getAllSettingTemplateIds
   */
  private getAllSettingTemplateIds(): void {
    if (!this.routeSelected) {
      return;
    }
    let areaGroupName = this.extractNameFromFormat(this.timetableSelected.name);
    const areaGroup = this.elements.find(e => e.name == areaGroupName);
    this.scheduleMergeService.getDisplaySettingSchedule(areaGroup.id).subscribe(data => {
      if (!data) {
        return;
      }
      const templateIds = JSON.parse(data['templateIds']);
      this.templateIds = [...new Set(templateIds.filter(element => element))];
      this.getTemplateByTemplateIds(this.templateIds);
    });
  }

  /**
   * handleError
   * @param error
   */
  private handleError(error: any) {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('schedule-merge.msg.title-error'),
        text:
          error.error?.detail == Constant.DUPLICATE_TIMETABLE_ID
            ? this.translateService.instant('schedule-merge.msg.duplicate-timetable-id')
            : this.translateService.instant('schedule-merge.msg.common-error')
      }
    });
  }

  /**
   * get Template By TemplateIds
   * @param templateIds
   */
  private getTemplateByTemplateIds(templateIds: Array<Number>): void {
    this.templateService.getTemplateByTemplateIds(templateIds).subscribe(templates => {
      this.templates = templates.map((template: any) => {
        return Helper.convertDataTemplateBackward(template);
      });
    });
  }

  /**
   * Handle show error message from server when import
   *
   * @param error
   */
  private handleShowErrorMessageFromServerWhenImport(error: any): void {
    switch (error.error?.detail) {
      case Constant.DUPLICATE_NO:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-confirm.title'),
            text: this.translateService.instant('schedule-merge.msg.duplicate-no')
          }
        });
        break;
      case Constant.HEADERS_NOT_MAP:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.headers-not-mapping')
          }
        });
        break;
      case Constant.ERROR_MULTIPLE_TIME_FORMATS_VALUE_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.multiple-time-formats-value')
          }
        });
        break;
      case Constant.ERROR_INVALID_DATA_TIMETABLE_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.contains-files-with-invalid-data')
          }
        });
        break;
      case Constant.ERROR_INVALID_DATA_FORMAT_TIMETABLE_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.malformed-name-containing-an-area-group-name')
          }
        });
        break;
      case Constant.ERROR_AREA_GROUP_NAME_DOES_NOT_EXIST:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.area-group-name-does-not-exist')
          }
        });
        break;
      case Constant.ERROR_NO_DATA_TIMETABLE_IN_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.no-data-timetable')
          }
        });
        break;
      case Constant.ERROR_HEADER_IS_EMPTY:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.header-is-empty')
          }
        });
        break;
      case Constant.ERROR_EXISTS_NAME_TIMETABLE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.duplicate-name')
          }
        });
        break;
      case Constant.ERROR_EXISTS_SUFFIX_NO:
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('schedule-merge.msg.duplicate-no-and-suffix'),
              button1: this.translateService.instant('schedule-merge.msg.overwrite-all'),
              button2: this.translateService.instant('schedule-merge.cancel'),
              button3: this.translateService.instant('schedule-merge.msg.add')
            }
          },
          result => {
            if (result == true) {
              this.dialogService.showDialog(
                DialogConfirmComponent,
                {
                  data: {
                    text: this.translateService.instant('schedule-merge.msg.confirm-overwrite-all'),
                    button1: this.translateService.instant('schedule-merge.msg.yes'),
                    button2: this.translateService.instant('schedule-merge.msg.cancel')
                  }
                },
                result => {
                  if (result) {
                    this.scheduleMergeService.overWriteTimetables().subscribe(timetables => {
                      this.timetableSelected = null;
                      this.handleAfterGetTimetables(timetables);
                      this.dataService.sendData([
                        Constant.IS_RECORDS_TIMETABLE_MAX,
                        this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE
                      ]);
                    });
                  } else {
                    let error = {
                      error: {
                        detail: 'Error exists suffix and no'
                      }
                    };
                    this.handleShowErrorMessageFromServerWhenImport(error);
                  }
                }
              );
            } else if (result == Constant.BUTTON_ADD_IMPORT_TIMETABLE) {
              this.scheduleMergeService.addTimetables().subscribe(timetables => {
                this.handleAfterGetTimetables(timetables, 'add');
                this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
              });
            }
          }
        );
        break;
      default:
        Helper.handleError(error, this.translateService, this.dialogService);
        break;
    }
  }

  /**
   * get regex time
   */
  private getRegexTime(): RegexTime {
    const regexTimeMinute1 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE1_REGEX, this.timeDateLine);
    const regexTimeMinute2 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE2_REGEX, this.timeDateLine);
    const regexTimeSecond = Helper.formatTimeRegex(Constant.FORMAT_TIME_SECOND_REGEX, this.timeDateLine);
    return new RegexTime(regexTimeMinute1, regexTimeMinute2, regexTimeSecond);
  }

  /**
   * handleAfterReadFile
   * @param timetableDB
   */
  private handleAfterGetTimetables(timetableDB: any, action?: string): void {
    if (action == 'merge' || action == 'add') {
      let timetablestemp = Helper.convertTimetableAfterImport(timetableDB, this.timeDateLine);
      let idsAdd = timetablestemp.map(item => item.id);
      let timetableClone = _.cloneDeep(this.timetables);
      _.remove(idsAdd, function(id) {
        return timetableClone.map(element => element.id).includes(id);
      });
      let listTimetableAdd = new Array<Timetable>();
      timetablestemp?.forEach(data => {
        if (idsAdd?.includes(data.id)) {
          listTimetableAdd.push(data);
        }
      });
      this.convertSchedule(listTimetableAdd);
      timetablestemp?.forEach(timetable => {
        if (!this.timetables.find(data => data.id === timetable.id)) {
          this.timetables.push(timetable);
        }
      });
      this.convertSchedule(this.timetables);
      this.timetablesDisplay = [...this.timetablesDisplay, ...listTimetableAdd];
    } else {
      this.handleDisplayImport(timetableDB);
      this.convertSchedule(this.timetables);
    }
    this.selectTimetable(this.timetablesDisplay[0], null);
  }

  /**
   * handleAfterAdds
   * @param timetablesresult
   */
  private handleDisplayImport(timetablesresult: any): void {
    let oldTimetables = _.cloneDeep(this.timetables);
    this.timetables = Helper.convertTimetableAfterImport(timetablesresult, this.timeDateLine);
    let timetablesAdd = _.cloneDeep(this.timetables);
    let displayTemp = _.cloneDeep(this.timetablesDisplay);
    let IdsDisplay = [...new Set(displayTemp.map(item => item.id))];
    this.timetablesDisplay = new Array<Timetable>();
    _.remove(timetablesAdd, function(timetable) {
      return oldTimetables.map(item => item.id).includes(timetable.id);
    });
    IdsDisplay = [...IdsDisplay, ...timetablesAdd?.map(data => data.id)];
    IdsDisplay?.forEach(id => {
      this.timetablesDisplay.push(this.timetables.find(timetable => timetable.id == id));
    });
  }
  /**
   * multi language message error
   * @returns
   */
  public multiLanguageTooltip(): void {
    if (!this.timetableSelected?.schedules?.length) {
      return;
    }
    this.timetableSelected?.schedules?.forEach(item => {
      let timeInvalid = Helper.formatString(
        this.translateService.instant('schedule-merge.time-invalid'),
        `${this.timetableSelected.headers[0]}`
      );
      if (item.inValidRow) {
        item.titleTooltip = timeInvalid;
      }
    });
  }

  /**
   * show icon arrow sort filter
   */
  private sortFilter(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.isSortFilter = !this.isSortFilter;
    this.sortFilterObject.isSortFilter = this.isSortFilter;
    this.saveSortFilterStateAction();
    if (!this.isSortFilter) {
      this.resetSortFilter();
    }
  }
  private sortFilterDaily(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.isSortFilterDaily = !this.isSortFilterDaily;
  }
  /**
   * Save sort filter state action
   */
  private saveSortFilterStateAction(): void {
    // Đoạn này lưu lên store để giữ trạng thái sort khi chia mh -> để làm sau nhé.
    // this.store.dispatch(
    //   new SaveSortFilterStateAction({
    //     sortFilterSr: this.sortFilterObject
    //   })
    // );
  }

  /**
   * reset sort filter action
   */
  private resetSortFilter(): void {
    this.getAllScheduleMerge();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    // store data sort filter
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listSorted = this.listSorted;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
    this.multiLanguageHeader();
  }

  /**
   * multi language header
   */
  private multiLanguageHeader(): void {
    this.headerColumns.forEach((element, index) => {
      switch (index) {
        case 0:
          element.headerName = this.translateService.instant('schedule-merge.no');
          break;
        case 1:
          element.headerName = this.translateService.instant('schedule-merge.suffix');
          break;
        case 2:
          element.headerName = this.translateService.instant('schedule-merge.name');
          break;
        default:
          break;
      }
    });
  }

  /**
   * Filter timetable when open timetable editor first time
   *
   * @returns
   */
  private filterTimetableFirstTime(): void {
    if (!this.listCurrentFilter) {
      this.selectTimetable(this.timetables[Constant.FIRST_ELEMENT_INDEX], null);
      return;
    }
    let listCurrentFilter = _.cloneDeep(this.listCurrentFilter);
    let columnSortFilters = Object.keys(listCurrentFilter);
    columnSortFilters.forEach(columnSortFilter => {
      this.listFilterDisplay = _.cloneDeep(listCurrentFilter)[columnSortFilter];
      this.listFilterDisplayOrigin = this.listFilterDisplay;
      this.filterTimetable(columnSortFilter, true);
    });
  }

  /**
   * filter timetable
   *
   * @param property
   * @param isFilterFirstTime
   */
  public filterTimetable(property: string, isFilterFirstTime: boolean): void {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      this.saveSortFilterStateAction();
      return;
    }
    this.isFilter = true;
    this.sortFilterObject.isFilter = this.isFilter;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;
      this.sortFilterObject.isFilter = this.isFilter;
      this.sortFilterObject.isClear = this.isClear;
      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      this.sortFilterObject.headerColumns = this.headerColumns;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.timetables?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listTimetableGetOptionFilter = this.timetables?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listTimetableOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listTimetableOptionFilter[i].lastFilter,
            name: listTimetableOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
        this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list Timetable show up on screen
    // this.timetables?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    let listFilterTmp = _.cloneDeep(this.timetables);
    for (let filterTmp in this.listCurrentFilter) {
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    this.timetablesDisplay = listFilterTmp;
    this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.saveSortFilterStateAction();
    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    }
  }
  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * set lastFilter for Timetable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.timetables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.timetables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin.every(filter => filter.isChecked);
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * show custom sort dialog and sort
   */
  public showCustomSort(): void {
    this.isShowPopUpSortFilter = false;
    this.saveSortFilterStateAction();
    let propertySorts = _.cloneDeep(this.headerColumns);
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }
        this.sortFilterObject.listSorted = this.listSorted;
        this.sortFilterObject.headerColumns = this.headerColumns;
        this.saveSortFilterStateAction();
        // sort
        this.timetablesDisplay = this.timetables?.filter(data => !data[this.LAST_FILTER]);
        this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }
  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }
  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.isFilter = false;
    this.sortFilterObject.isFilter = this.isFilter;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    // if is last column filter
    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.sortFilterObject.isClear = this.isClear;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      this.filterTimetable(property, false);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.timetables?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listTimetableGetOptionFilter = this.timetables?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listTimetableOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listTimetableOptionFilter[i].lastFilter,
          name: listTimetableOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
          if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      this.saveSortFilterStateAction();
      this.controlCheckBoxCheckAllFilter();
      this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    }
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    this.saveSortFilterStateAction();
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * show pop up sort filter
   * @param column name column
   * @param event
   */
  public showPopupSortFilter(column: string, event): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = column;
      this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
      this.fetchFilterData(column);
    }
    this.saveSortFilterStateAction();
  }

  /**
   * fetchFilterData
   * @param property
   */
  public fetchFilterData(property: string): void {
    // let listDataTableGetOptionFilter = this.dataTablesDisplay.filter(
    //   data => !data[this.LAST_FILTER] || data[this.LAST_FILTER] === property
    // );
    let isFiltered = false;
    let listFilterTmp = _.cloneDeep(this.timetables);
    let listFilterInProperty = [];
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == property) {
        isFiltered = true;
        listFilterInProperty = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        continue;
      }

      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let listDataTableGetOptionFilter = _.cloneDeep(listFilterTmp);
    let listDataTableOptionFilter: any[] = this.getUniqueOption(listDataTableGetOptionFilter, property);
    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < listDataTableOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: listFilterInProperty.length == 0 ? true : listFilterInProperty.includes(listDataTableOptionFilter[i][property]),
          // isChecked: !listDataTableOptionFilter[i][this.IS_FILTER],
          name: listDataTableOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      listDataTableOptionFilter.forEach(dataTable => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === dataTable[property])) {
          this.listFilterDisplay.push({
            isChecked: !isFiltered,
            name: dataTable[property]
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !listDataTableOptionFilter.find(dataTable => dataTable[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }
  /**
   * Get all sort filter conditions
   */
  private getAllSortFilterConditions(): void {
    // get data from store
    this.isFilter = this.sortFilterObject?.isFilter;
    this.isSortFilter = this.sortFilterObject?.isSortFilter;
    this.isCheckAllOptionFilter = this.sortFilterObject?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.sortFilterObject?.columnSortFiltering;
    this.listCurrentFilter = this.sortFilterObject?.listCurrentFilter;
    this.isClear = this.sortFilterObject?.isClear;
    this.listSorted = this.sortFilterObject?.listSorted;
    if (this.isSortFilter && (!_.isEmpty(this.listCurrentFilter) || this.listSorted[Constant.SORT_COLUMN_INDEX])) {
      this.headerColumns = this.sortFilterObject?.headerColumns;
    } else {
      this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    }
    this.multiLanguageHeader();
  }
  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    this.isShowPopUpSortFilter = false;
    // store data sort filter
    this.sortFilterObject.listSorted = this.listSorted;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  async exportTimetable(): Promise<void> {
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return;
    }
    let timetablesChecked = this.timetables.filter(timetable => timetable.isChecked);
    if (!timetablesChecked?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-registration.choose-timetable')
        }
      });
      return;
    }
    // case timetables checked > NUMBER_OF_FILES_ALLOWED_TO_EXPORT
    if (timetablesChecked.length > Constant.NUMBER_OF_FILES_ALLOWED_TO_EXPORT) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('schedule-registration.maximum-files'),
            `${Constant.NUMBER_OF_FILES_ALLOWED_TO_EXPORT}`
          )
        }
      });
      return;
    }
    if (timetablesChecked.some(data => data.name.match(Constant.FORMAT_TIMETABLE_NAME) || data.name.includes('\\'))) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-registration.name-special-character')
        }
      });
      return;
    }

    timetablesChecked = timetablesChecked.map(timetableChecked =>
      Helper.convertTimetableScheduleMergeToBackWardWhenExport(timetableChecked)
    );
    for (const timetable of timetablesChecked) {
      let noTimetable = timetable.no;
      let month = +noTimetable.substring(2);
      let year = +`20${noTimetable.substring(0, 2)}`;
      let dateData = Helper.getDateByMonth(year, month);
      let dayInsMonth = Helper.daysInMonth(dateData);
      this.scheduleMergeService.writeExcel(timetable.id, dayInsMonth, month).subscribe(
        response => {
          this.uncheckAllTimetable();
          const fileNameResponse = decodeURIComponent(response.headers.get('content-disposition'));
          const file = new File([response.body], fileNameResponse, {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          fileSaver.saveAs(file);
        },
        error => {
          this.handleErrorSaveTimetable(error);
        }
      );
    }
  }

  /**
   * handle Error Save Timetable
   * @param error
   */
  private handleErrorSaveTimetable(error: any): void {
    let msg = this.translateService.instant('dialog-error.msg');
    if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      msg = this.translateService.instant('timetable-editor.limit-record-timetable');
    } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME_TIMETABLE) {
      msg = this.translateService.instant('timetable-editor.duplicate-name');
    } else if (error.error?.detail == Constant.ERROR_EXISTS_SUFFIX_NO) {
      msg = this.translateService.instant('timetable-editor.duplicate-timetable');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: msg
      }
    });
  }

  /**
   * Uncheck all timetable
   */
  private uncheckAllTimetable(): void {
    this.timetables.forEach(timetable => (timetable.isChecked = false));
    this.timetablesDisplay.forEach(timetableDisplay => (timetableDisplay.isChecked = false));
  }

  /**
   * update Text Highlight Setting
   */
  public updateTextHighlightSetting(): void {
    this.dialogService.showDialog(
      DialogTextHighlightingSettingComponent,
      {
        data: {
          properties: this.headerColumnsOriginalSecond?.map(header => header.headerName)
        },
        maxHeight: '90vh'
      },
      result => {
        if (result == undefined) {
          return;
        }
      }
    );
  }
  /**
   * add timetable
   * @returns
   */
  private addTimetables(): void {
    if (this.timetableSelected && this.timetableSelected.isEdit && this.tabSelected != Tab_Enum.MONTHLY) {
      return;
    }
    this.noEdit = Constant.EMPTY;
    this.suffixEdit = Constant.EMPTY;
    this.nameEdit = Constant.EMPTY;
    let timetableNew = new Timetable(null, this.noEdit, this.suffixEdit, this.nameEdit, null);
    timetableNew.isEdit = true;
    this.timetables.push(timetableNew);
    this.timetablesDisplay.push(timetableNew);
    this.timetableSelected = timetableNew;
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
  }

  /**
   * save timetable
   */
  public async saveTimetable(): Promise<void> {
    let no = this.noEdit;
    let suffix = this.suffixEdit;
    let name = this.nameEdit;
    // validate timetable No, Name, suffix
    if (!(await this.validateTimetable(no, suffix, name))) {
      return;
    }
    // enter suffix full empty character
    if (!suffix.trim().length) {
      suffix = suffix.trim();
      this.suffixEdit = suffix;
    }

    this.scheduleMergeService.checkExistTimetable(no, suffix, this.timetableSelected.id ?? null).subscribe(data => {
      if (data) {
        this.noElementRef.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.duplicate-timetable')
          },
          disableClose: true
        });
        this.saveDataSuccess.emit(false);
        return;
      }
      this.scheduleMergeService.checkExistTimetableName(name, this.timetableSelected.id ?? null).subscribe(data => {
        if (data) {
          this.nameElementRef.nativeElement.focus();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('timetable-editor.duplicate-name')
            },
            disableClose: true
          });
          this.saveDataSuccess.emit(false);
          return;
        }
        this.timetableSelected.name = name;
        this.timetableSelected.no = no;
        this.timetableSelected.suffix = suffix;
        if (this.timetableSelected?.id) {
          this.scheduleMergeService
            .saveEditTimetable(this.timetableSelected.id ?? null, this.timetableSelected?.suffix, this.timetableSelected?.name)
            .subscribe(
              timetableData => {
                this.handleAfterSaveTimetable();
                if (!timetableData) {
                  return;
                }
                let timetableTemp = Helper.convertDataScheduleMerge(timetableData);
                let index = this.timetables.findIndex(timetable => timetable.id === timetableTemp.id);
                if (index == -1) {
                  return;
                }
                let timetableEdited = this.timetables[index];
                timetableEdited.suffix = timetableTemp.suffix;
                timetableEdited.name = timetableTemp.name;
                timetableEdited.isChecked = this.timetableSelected.isChecked;
                timetableEdited.isEdit = this.timetableSelected.isEdit;
                this.timetables[index] = timetableEdited;
                let indexTimetableEditOnDisplay = this.timetablesDisplay.findIndex(timetable => timetable.id === timetableEdited.id);
                if (indexTimetableEditOnDisplay > -1) {
                  this.timetablesDisplay[indexTimetableEditOnDisplay] = this.timetables[index];
                }
                this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
                this.selectTimetable(this.timetablesDisplay[index], null);
              },
              error => {
                this.handleErrorSaveTimetable(error);
                this.saveDataSuccess.emit(false);
              }
            );
        } else {
          this.scheduleMergeService
            .saveAddDuplicateTimetable(
              this.idTimetableOld ?? null,
              this.timetableSelected.no,
              this.timetableSelected.suffix,
              this.timetableSelected.name
            )
            .subscribe(
              data => {
                this.handleAfterSaveTimetable();
                if (!data) {
                  return;
                }
                let timetableAddOrDuplicate = Helper.convertDataScheduleMerge(data);
                timetableAddOrDuplicate.schedules = this.timetableSelected.schedules;
                timetableAddOrDuplicate.isEdit = this.timetableSelected.isEdit;
                this.convertSchedule([timetableAddOrDuplicate]);
                this.timetables[this.timetables.length - 1] = timetableAddOrDuplicate;
                this.timetablesDisplay[this.timetablesDisplay.length - 1] = timetableAddOrDuplicate;
                this.idTimetableOld = null;
                this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
                this.selectTimetable(timetableAddOrDuplicate, null);
              },
              error => {
                this.handleErrorSaveTimetable(error);
                this.saveDataSuccess.emit(false);
              }
            );
        }
      });
    });
    this.dataStoredSecond = null;
  }

  /**
   * handleAfterSaveTimetable
   */
  private handleAfterSaveTimetable(): void {
    this.timetableSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    this.saveDataSuccess.emit(true);
  }

  /**
   * cancel save timetable
   */
  public cancelSaveTimetable(): void {
    this.timetableSelected.isEdit = false;
    this.idTimetableOld = null;
    let indexTimetable = this.timetables.findIndex(timetable => timetable.id == this.timetableSelected.id);
    if (indexTimetable != -1) {
      this.timetables[indexTimetable].isEdit = false;
    }
    let index = this.timetablesDisplay.findIndex(timetableDisplay => timetableDisplay.id == this.timetableSelected.id);
    if (index != -1) {
      this.timetablesDisplay[index].isEdit = false;
    }
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    if (!this.timetableSelected.id) {
      this.timetables.pop();
      this.timetablesDisplay.pop();
      if (this.timetablesDisplay?.length) {
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      } else {
        this.timetableSelected = undefined;
      }
    } else {
      this.selectTimetable(this.timetableSelected, null);
    }
  }

  /**
   * validate timetable when add new/ edit
   * @param no
   * @param suffix
   * @param name
   */
  private async validateTimetable(no: string, suffix: string, name: string): Promise<boolean> {
    //validate with no
    if (!this.validateNo(no)) {
      return false;
    }

    // validate suffix
    if (!this.validateSuffix(suffix)) {
      return false;
    }

    // validate timetable name
    if (!this.validateName(name)) {
      return false;
    }
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * edit time table
   */
  private editTimetable(): void {
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.timetableSelected.isEdit) {
      return;
    }
    this.noTimetableOld = _.cloneDeep(this.timetableSelected.no);
    this.noEdit = this.timetableSelected.no;
    this.suffixEdit = this.timetableSelected.suffix;
    this.nameEdit = this.timetableSelected.name;
    this.timetableSelected.isEdit = true;
    let indexTimetable = this.timetables.findIndex(timetable => timetable.id == this.timetableSelected.id);
    if (indexTimetable != -1) {
      this.timetables[indexTimetable].isEdit = true;
    }
    let index = this.timetablesDisplay.findIndex(timetableDisplay => timetableDisplay.id == this.timetableSelected.id);
    if (index != -1) {
      this.timetablesDisplay[index].isEdit = true;
    }
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
  }

  /**
   * validate no
   * @param no
   * @returns
   */
  private validateNo(no: string): boolean {
    // validate timetable no
    if (no.trim().length < Constant.MIN_NO_LENGTH) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-empty')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (no.length != Constant.NO_LENGTH_EXACTLY) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-merge.msg.no-length-exactly')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (!no.match(Constant.FORMAT_NO_MERGE)) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-merge.msg.no-format-merge')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (!this.validateNoDate(no)) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-merge.msg.no-format-date')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * validate no date
   * @param no
   * @returns
   */
  private validateNoDate(no: string): boolean {
    let noYear = no.substring(0, 2);
    let noMonth = no.substring(2);
    if (parseInt(noMonth) < 1 || parseInt(noMonth) > 12) {
      return;
    }
    // check year of past
    if (parseInt(noYear) < this.currentDate.getFullYear() - 2000) {
      return false;
    }
    // check year now and month of past
    if (parseInt(noYear) == this.currentDate.getFullYear() - 2000 && parseInt(noMonth) < this.currentDate.getMonth() + 1) {
      return false;
    }
    // check year of future
    if (parseInt(noYear) - 2 > this.currentDate.getFullYear() - 2000) {
      return false;
    }
    if (parseInt(noYear) - 2 == this.currentDate.getFullYear() - 2000 && parseInt(noMonth) > this.currentDate.getMonth() + 1) {
      return false;
    }
    return true;
  }

  /**
   * validate suffix
   * @param suffix
   * @returns
   */
  private validateSuffix(suffix: string): boolean {
    if (suffix.trim().length != 0 && suffix.length > Constant.MAX_SUFFIX_LENGTH) {
      this.suffixElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.suffix-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (suffix.trim().length != 0 && !suffix.match(Constant.FORMAT_SUFFIX_REGEX)) {
      this.suffixElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.suffix-format')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * validate name
   * @param name
   * @returns
   */
  private validateName(name: string): boolean {
    if (name.trim().length < Constant.MIN_NAME_LENGTH) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-empty')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (name.length > Constant.MAX_NAME_LENGTH) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (name.match(Constant.FORMAT_TIMETABLE_NAME) || name.trim().includes('\\')) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-special-character')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    const match = name.match(Constant.REGEX_TIMETABLE_NAME_CONTAINS_THE_AREA_GROUP_NAME);
    if (match) {
      const areaGroupName = match[2];
      const listElement = this.elements?.map(e => e?.name);
      if (!listElement.includes(areaGroupName)) {
        this.nameElementRef.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            textHasLineBreaks: this.translateService.instant('schedule-merge.msg.area-group-name-does-not-exist')
          }
        });
        this.saveDataSuccess.emit(false);
        return false;
      }
    } else {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-merge.msg.malformed-name-containing-an-area-group-name')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    return true;
  }

  /**
   * duplicate timetable
   */
  private duplicateTimetable(): void {
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.timetableSelected && this.timetableSelected.isEdit && this.tabSelected != Tab_Enum.MONTHLY) {
      return;
    }
    this.idTimetableOld = _.cloneDeep(this.timetableSelected.id);
    this.noEdit = this.timetableSelected.no;
    this.suffixEdit = this.timetableSelected.suffix;
    this.nameEdit = this.timetableSelected.name;
    let timetableNew = new Timetable(null, this.timetableSelected.no, this.suffixEdit, this.nameEdit, null);
    timetableNew.isEdit = true;
    timetableNew.headers = this.timetableSelected.headers;
    timetableNew.schedules = JSON.parse(JSON.stringify(this.timetableSelected.schedules));
    const tmpDate = this.getCalendarsForSchedule(timetableNew?.no);
    timetableNew.schedules?.forEach(schedule => {
      schedule.contentDays.forEach((contentDay: any, index) => {
        contentDay.fullDate = new Date(tmpDate[index].fullDate);
        contentDay.dateInMonth = contentDay.fullDate.getDate();
      });
    });
    this.timetableSelected = timetableNew;
    this.timetables.push(timetableNew);
    this.timetablesDisplay.push(timetableNew);
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
  }

  /**
   * delete timetable
   */
  private deleteTimetable(): void {
    let timetablesChecked = this.timetables.filter(timetable => timetable.isChecked);
    if (!timetablesChecked?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    }
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant(`timetable-editor.delete-checked-timetables`),
          button1: this.translateService.instant('timetable-editor.yes'),
          button2: this.translateService.instant('timetable-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.handleDeleteTimetables(timetablesChecked);
        this.dataStoredSecond = null;
      }
    );
  }

  /**
   * show previous month
   */
  public showPrevMonth(): void {
    if (!this.routes.length) {
      return;
    }
    // return if < start date of device
    if (this.selectedYear == this.currentDate.getFullYear()) {
      if (this.selectedMonth <= this.currentDate.getMonth()) {
        this.isPreviousMonth = true;
        return;
      }
    }
    this.isPreviousMonth = false;
    this.isNextMonth = false;
    this.selectedMonth = this.selectedMonth <= 0 ? 11 : this.selectedMonth - 1;
    this.selectedYear = this.selectedMonth == 11 ? this.selectedYear - 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
      this.calendarsInTwoYear,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
    this.changeStatusUserCalendar();
  }

  /**
   * show next month
   */
  public showNextMonth(): void {
    if (!this.routes.length) {
      return;
    }
    // return if > finish date of device
    let dateEnd = _.cloneDeep(this.currentDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd.setDate(dateEnd.getDate() - 1);
    if (this.selectedYear == this.currentDate.getFullYear() + Constant.MAX_YEAR) {
      if (this.selectedMonth >= dateEnd.getMonth()) {
        this.isNextMonth = true;
        return;
      }
    }
    this.isNextMonth = false;
    this.isPreviousMonth = false;
    this.selectedMonth = this.selectedMonth >= 11 ? 0 : this.selectedMonth + 1;
    this.selectedYear = this.selectedMonth == 0 ? this.selectedYear + 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
      this.calendarsInTwoYear,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
    this.changeStatusUserCalendar();
  }

  /**
   * changeStatusUserCalendar
   */
  private changeStatusUserCalendar() {
    this.userCalendars.forEach(userCalendar => {
      if (userCalendar.calendars) {
        let calendarsInTwoYear = Helper.getCalendarsFromCurrentDate();
        let contentDays = userCalendar.calendars.map(contentDayData => {
          return Helper.convertDataContentDayTabRegistration(contentDayData);
        });
        contentDays.forEach(contentDay => {
          let date = Helper.getDateByDay(
            contentDay.fullDate.getFullYear(),
            contentDay.fullDate.getMonth() + 1,
            contentDay.fullDate.getDate()
          );
          let index = calendarsInTwoYear.findIndex(content => content.fullDate.getTime() == date.getTime());
          if (index != -1) {
            contentDay.day = calendarsInTwoYear[index].day;
            calendarsInTwoYear[index] = contentDay;
          }
        });

        let contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
          calendarsInTwoYear,
          this.selectedMonth + 1 ? this.selectedMonth : this.currentDate.getMonth(),
          this.selectedYear ? this.selectedYear : this.currentDate.getFullYear(),
          this.commonObject
        );
        let monthSelected = this.selectedMonth ?? this.currentDate.getMonth();
        _.remove(contentDaysMonth, function(contentDayMonth) {
          return contentDayMonth.fullDate.getMonth() != monthSelected;
        });
        if (contentDaysMonth.some(contentDayMonth => contentDayMonth.styleId)) {
          userCalendar.status = true;
          const index = this.routes?.findIndex(route => route.id == userCalendar.routeId);
          this.routes[index].status = userCalendar.status;
        } else {
          userCalendar.status = false;
          const index = this.routes?.findIndex(route => route.id == userCalendar.routeId);
          this.routes[index].status = userCalendar.status;
        }
      } else {
        userCalendar.status = false;
        const index = this.routes?.findIndex(route => route.id == userCalendar.routeId);
        this.routes[index].status = userCalendar.status;
      }
    });
    this.isAlreadyAllCompany = this.routes.every(item => item.status);
  }

  /**
   * select month
   * @param month any
   */
  public selectMonth(month: any): void {
    this.selectedMonth = +month;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
      this.calendarsInTwoYear,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
    this.changeStatusUserCalendar();
  }

  /**
   * select selectUserCalendar
   * @param {UserCalendar} userCalendar selected userCalendar
   */
  public selectUserCalendar(userCalendar: UserCalendar): void {
    if (!userCalendar) {
      return;
    }
    this.selectedUserCalendar = userCalendar;
    if (!this.selectedMonth) {
      this.currentDate = new Date(Helper.getCurrentByTimezoneSetting(this.commonObject));
      this.selectedYear = this.currentDate.getFullYear();
      this.selectedMonth = this.currentDate.getMonth();
    }
    this.calendarsInTwoYear = Helper.getCalendarsFromCurrentDate();
    if (this.selectedUserCalendar.calendars) {
      let contentDays = this.selectedUserCalendar.calendars.map(contentDayData => {
        return Helper.convertDataContentDayTabRegistration(contentDayData);
      });
      contentDays.forEach(contentDay => {
        let date = Helper.getDateByDay(
          contentDay.fullDate.getFullYear(),
          contentDay.fullDate.getMonth() + 1,
          contentDay.fullDate.getDate()
        );
        let index = this.calendarsInTwoYear.findIndex(content => content.fullDate.getTime() == date.getTime());
        if (index != -1) {
          contentDay.day = this.calendarsInTwoYear[index].day;
          this.calendarsInTwoYear[index] = contentDay;
        }
      });
    }
    // get contentDays by month selected and year selected
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
      this.calendarsInTwoYear,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }
  // /**
  //  * get all user calendars
  //  */
  // private getAllUserCalendars(): void {
  //   this.userService.getUsersForScheduleMerge().subscribe(data => {
  //     this.userCalendars = Helper.convertDataToUserCalendars(data, this.translateService);
  //     if (!this.userCalendars.length) {
  //       return;
  //     }
  //     this.scheduleMergeService.getCalendarByAccount(this.userCalendars).subscribe(
  //       contentDaysData => {
  //         this.userCalendars = Helper.convertDataToUserCalendars(contentDaysData, this.translateService);
  //         this.changeStatusUserCalendar();
  //         if (this.userCalendars?.length) {
  //           this.selectUserCalendar(this.userCalendars[0]);
  //         }
  //       },
  //       error => Helper.handleError(error, this.translateService, this.dialogService)
  //     );
  //   });
  // }

  /**
   * getCalendarsByRoutes
   * @returns
   */
  private getCalendarsByRoutes(): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = this.createPayloadGetCalendar();
      this.scheduleMergeService.getCalendarByRoutes(payload).subscribe(
        data => {
          this.userCalendars = Helper.convertDataToUserCalendars(data, this.translateService);
          this.mergeRoute();
          this.changeStatusUserCalendar();
          this.selectRoute(this.routeSelected);
          resolve();
        },
        error => reject(error)
      );
    });
  }

  /**
   * mergeRoute
   */
  mergeRoute(): void {
    let userCalendarsTemp = new Array<UserCalendar>();
    for (let calendar of this.userCalendars) {
      if (!userCalendarsTemp.includes(calendar) && !userCalendarsTemp.some(e => e.routeId == calendar.routeId)) {
        userCalendarsTemp.push(calendar);
      } else if (!userCalendarsTemp.includes(calendar) && userCalendarsTemp.some(e => e.routeId == calendar.routeId)) {
        const index = userCalendarsTemp.findIndex(e => e.routeId == calendar.routeId);
        userCalendarsTemp[index].calendars = [...userCalendarsTemp[index].calendars, ...calendar.calendars];
      }
    }
    this.userCalendars = [...userCalendarsTemp];
  }

  /**
   * getAllRoute
   * @returns
   */
  private getAllRoute(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customTagService.getCustomTag().subscribe(
        customTagDataList => {
          let customTagAreaGroup = customTagDataList.find(data => data.name.includes('Area_Group'));
          this.elements = customTagAreaGroup.elements;
          if (this.elements && this.elements.length) {
            this.elements[0].isExpand = true;
            const elementIds = this.elements.map(e => e.id);
            this.customTagService.getRouteByElements(elementIds).subscribe(routes => {
              this.routes = routes;
              const index = this.routes.findIndex(route => route.elementId == this.elements[0].id);
              this.routeSelected = this.routes[index];

              this.getCalendarsByRoutes()
                .then(() => {
                  resolve();
                })
                .catch(error => reject(error));
            });
          } else {
            resolve();
          }
        },
        error => reject(error)
      );
    });
  }

  /**
   * createPayload
   * @returns
   */
  createPayloadGetCalendar() {
    let payload = {};
    for (let e of this.elements) {
      const elementName = e.name;
      const listRoute = this.routes?.filter(route => route.elementId == e.id);
      let subPayload = {
        [elementName]: listRoute
      };
      payload = { ...payload, ...subPayload };
    }
    return payload;
  }

  /**
   * mergeSchedule
   */
  public mergeSchedule(): void {
    if (this.tabSelected != Tab_Enum.CONFIRMATION || !this.routeSelected || this.checkReturn()) {
      return;
    }
    const no = `${this.selectedYear.toString().substring(2, 4)}${(this.selectedMonth + 1).toString().padStart(2, '0')}`;
    this.scheduleMergeService.mergeSchedule(no, this.createPayloadMerge()).subscribe(
      timetables => {
        if (!timetables) {
          this.chooseTab(Tab_Enum.MONTHLY);
        } else {
          this.handleAfterGetTimetables(timetables, 'merge');
          this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
          this.chooseTab(Tab_Enum.MONTHLY);
        }
      },
      error => {
        this.handleShowErrorMessageFromServerWhenImport(error);
      }
    );
  }

  checkReturn(): boolean {
    const routesCheck = this.routes.filter(route => route.elementId == this.routeSelected.elementId);
    return routesCheck.every(e => !e.status);
  }

  /**
   * createPayloadMerge
   * @returns
   */
  createPayloadMerge() {
    let payload = {};
    const areaGroupName = this.elements.find(e => e.id == this.routeSelected.elementId).name;
    payload = {
      [areaGroupName]: this.routes.filter(route => route.elementId == this.routeSelected.elementId)
    };
    return payload;
  }

  /**
   * handle delete timetables
   * @param timetablesChecked
   */
  private handleDeleteTimetables(timetablesChecked: Array<Timetable>): void {
    this.scheduleMergeService.deleteTimetables(timetablesChecked.map(timetable => timetable.id)).subscribe(() => {
      this.timetables = this.timetables.filter(timetable => !timetable.isChecked);
      this.timetablesDisplay = this.timetablesDisplay.filter(timetableDisplay => !timetableDisplay.isChecked);
      this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
      if (this.timetablesDisplay?.length) {
        if (this.timetableSelected?.isChecked) {
          this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        }
      } else {
        this.timetableSelected = undefined;
      }
    });
  }

  /**
   * setting channel area preview
   */
  public signageChannelAreaPreview(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    // get setting signage channel by project's id
    this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.SCHEDULE_MERGE).subscribe(
      settingSignageChannel => {
        this.dialogService.showDialog(
          DialogSettingSignageDisplayComponent,
          {
            data: {
              settingSignageChannel: <SettingSignageChannel>settingSignageChannel ?? new SettingSignageChannel(),
              type: SettingType.SCHEDULE_MERGE
            }
          },
          result => {
            if (result) {
              let data = result;
              if (!data || (!data.mediaId && !data.folderId)) {
                this.mediaSetting = undefined;
                this.drawScheduleMergeService.clearCanvasAreas(this.templateSelected);
                return;
              }
              // get media by id
              this.simpleMediaService.getMediaById(data.mediaId).subscribe(
                async mediaData => {
                  if (!mediaData) {
                    this.mediaSetting = undefined;
                    return;
                  }
                  this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(mediaData, false));
                  this.drawScheduleMergeService.setupPreview(this.timetableSelected, this.mediaSetting ?? undefined);
                  // draw area is set signage channel
                  this.drawScheduleMergeService.drawAreasSignageChannel(this.templateSelected, this.renderer);
                },
                error => Helper.handleError(error, this.translateService, this.dialogService)
              );
            } else {
              this.mediaSetting = undefined;
              this.drawScheduleMergeService.clearCanvasAreas(this.templateSelected);
            }
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * get content day by current month
   * @param contentDays
   * @param month
   * @returns
   */
  public getContentDayByCurrentMonth(contentDays: Array<ContentDaySchedule>, no: string): Array<ContentDaySchedule> {
    let month = +no.substring(2);
    let listContentDays = contentDays?.filter(contentDay => contentDay.fullDate.getMonth() + 1 == month);
    return listContentDays;
  }

  /**
   * get content day not active day
   * @param contentDays
   * @returns
   */
  public getContentDayNotActiveDay(contentDays: Array<ContentDaySchedule>) {
    let listContentDays = contentDays.filter(contentDay => !contentDay.isActive);
    return listContentDays;
  }

  /**
   * draw areas index word for display
   * @param display
   * @param areasIndexWordDisplay
   * @param canvasDisplayId
   * @param indexWords
   */
  private drawAreasIndexWordForDisplay(display: Template, areasIndexWordDisplay: Area[], indexWords: IndexWord[]): void {
    Helper.setDataIndexWordForAreas(areasIndexWordDisplay, indexWords);
    this.drawScheduleMergeService.setDataPreviewTimetableEditor(null, null, null, areasIndexWordDisplay);
    let areasIndexWordLayerOff = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, display, this.areaSwitchingTiming);

    this.drawScheduleMergeService.drawAreasIndexWord(areasIndexWordLayerOff, this.timetablesDisplaySecond, display);
    let areasIndexWordLayerOn = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, display, this.areaSwitchingTiming, true);
    this.drawScheduleMergeService.drawAreasIndexWordLayerOn(areasIndexWordLayerOn, this.timetablesDisplaySecond, display);
  }

  /**
   * draw areas timetable for display
   * @param display
   * @param index
   * @param areasTimetableDisplay
   * @param canvasDisplayId
   */
  private drawAreasTimetableForDisplay(display: Template, areasTimetableDisplay: TextArea[]): void {
    let areasTimetableLayerOff = Helper.getAreasOfLayerOnOff(areasTimetableDisplay, display, this.areaSwitchingTiming);
    this.drawScheduleMergeService.drawAreasTimetable(
      areasTimetableLayerOff as TextArea[],
      this.renderer,
      this.timetablesDailyDisplaySecond,
      this.currentIndexScheduleDaily,
      this.referencePositionColumnsByTemplate
    );
    let areasTimetableLayerOn = Helper.getAreasOfLayerOnOff(areasTimetableDisplay, display, this.areaSwitchingTiming, true);
    this.drawScheduleMergeService.drawAreasTimetableLayerOn(
      areasTimetableLayerOn as TextArea[],
      this.renderer,
      this.timetablesDailyDisplaySecond,
      this.currentIndexScheduleDaily,
      this.referencePositionColumnsByTemplate
    );
  }

  /**
   * convertSchedule
   * @param timetables
   */
  private convertSchedule(timetables: Timetable[]): void {
    timetables?.forEach(timetable => {
      timetable.schedules?.forEach(schedule => {
        if (!schedule.contentDays?.length) {
          schedule.contentDays = this.getCalendarsForSchedule(timetable.no);
          if (schedule.contentDays.length) {
            const index = schedule.contentDays.findIndex(day => day.isOtherMonth == false);
            let indexActive = 0;
            for (let i = index; i < index + Helper.getDaysInMonth(timetable?.no); i++) {
              schedule.contentDays[i].isActive = schedule.isActiveDays[indexActive];
              if (schedule?.isEditDays?.length) {
                schedule.contentDays[i].isEdit = schedule?.isEditDays[indexActive] ? true : false;
              }
              indexActive++;
            }
          }
        }
      });
    });
  }

  /**
   * get information change date line
   */
  private async getInformationChangeDateLine(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE).subscribe(
        data => {
          this.timeDateLine = data ? JSON.parse(data.value) : Constant.TIME_DATE_LINE_DEFAULT;
          resolve();
        },
        error => {
          reject();
          Helper.handleError(error, this.translateService, this.dialogService);
        }
      );
    });
  }

  /**
   * getAreaSwitching
   * @returns
   */
  private async getAreaSwitching(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.scheduleRegistrationService.getAreaSwitchingTiming().subscribe(
        data => {
          this.areaSwitchingTiming = data;
          this.drawScheduleMergeService.setAreaSwitchingTiming(this.areaSwitchingTiming);
          resolve();
        },
        error => reject()
      );
    });
  }
  /**
   * getRoutes
   * @param elementId
   * @returns
   */
  getRoutes(elementId: number): any {
    return this.routes.filter(route => route.elementId == elementId);
  }

  openCustomTagElement(route: any) {
    route.isExpand = !route.isExpand;
  }

  /**
   * selectRoute
   */
  selectRoute(route: any) {
    this.routeSelected = route;
    const index = this.userCalendars?.findIndex(calendar => calendar.routeId == route.id);
    if (index != -1) {
      this.selectUserCalendar(this.userCalendars[index]);
    } else {
      if (!this.selectedMonth) {
        this.currentDate = new Date(Helper.getCurrentByTimezoneSetting(this.commonObject));
        this.selectedYear = this.currentDate.getFullYear();
        this.selectedMonth = this.currentDate.getMonth();
      }
      this.calendarsInTwoYear = Helper.getCalendarsFromCurrentDate();
      // get contentDays by month selected and year selected
      this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
        this.calendarsInTwoYear,
        this.selectedMonth,
        this.selectedYear,
        this.commonObject
      );
    }
  }

  /**
   * send Timezone To Backend
   */
  private sendTimezoneToBack(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let timeZone = this.commonService
        .getCommonObject()
        .setting.timezone.name.split(' ')[0]
        .replace(')', '')
        .replace('(', '');
      this.timetableService.transferTimezoneToBack(timeZone).subscribe(data => resolve()), error => reject();
    });
  }
}

export enum Tab_Enum {
  CONFIRMATION,
  MONTHLY,
  DAILY
}
